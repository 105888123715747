<template>
  <v-data-table
      :headers="headers"
      :items="teachers"
      :search="search"
      :items-per-page=-1
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Team Members</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field class="mx-4"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon v-if="item.id == ownerId"
              class="mr-2"
      >
        mdi-star
      </v-icon>
      <v-tooltip top v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-icon :disabled="teachers.length < 2"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="mr-4"
              @click="removeTeacher(item)"
          >
            mdi-minus-circle
          </v-icon>
        </template>
        <span>Drop from class</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <span>No Teachers in this class? should not happen</span>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    teachers: {
      type: Array,
      default: () => [],

    },
    classId: {
      type: Number,
      default: null
    },
    ownerId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    headers: [
      { text: 'First Name', value: 'first_name', align: 'start'},
      { text: 'Last Name', value: 'last_name' },
      { text: 'Username', value: 'username' },
      { text: 'Teacher Id', value: 'id' },
      { text: 'User Id', value: 'user_id' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    search: ''
  }),
  methods: {
    async removeTeacher(teacher) {
      if (this.teachers.length < 2) return //don't remove the last teacher
      const confirmed = window.confirm(`You are about to remove ${teacher.username} from this class`)
      if (confirmed) {
        let url = `/class/${this.classId}/teacher/${teacher.id}`
        const {status} =  await this.axios.delete(url);
        if (status == 200) {
          this.teachers = this.teachers.filter(item => teacher.id !== item.id)
        }
      }
    },
  }

}

</script>
