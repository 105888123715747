<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="420"
    >
      <v-card class="pa-0 ma-0">
        <v-card-title class="headline">
          {{headline}}
        </v-card-title>
        <v-card-text>{{ headline_secondary }}</v-card-text>
        <v-container>
        <v-row>
          <user-auto-complete :on-selected="onSelected" ref="autoComplete"/>
        </v-row>
        </v-container>
        <v-card-text>{{text}}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="darken-1"
              text
              @click="onCancel"
          >
            Cancel
          </v-btn>
          <v-btn
              :disabled="!okEnabled"
              color="blue darken-1"
              text
              @click="onOk"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UserAutoComplete from "@/components/UserAutoComplete";
import _ from 'lodash'
export default {

  props: ['headline', 'headline_secondary', 'text'],
  name: "UserSelectDialog",
  components: {UserAutoComplete},
  data() {
    return {
      resolve: null,
      reject: null,
      dialog: false,
      selectedUser: null,
    }
  },
  computed: {
    okEnabled() {
      return !_.isEmpty(this.selectedUser)
    }
  },
  methods: {
    show() {
      this.selectedUser = null;
      this.dialog = true;
      const self = this;
      return new Promise((resolve, reject) => {
        self.resolve = resolve;
        self.reject = reject;
      });
    },
    onCancel() {
      this.dialog=false;
      if (this.reject) this.reject();
    },
    onSelected(value) {
      this.selectedUser = _.cloneDeep(value);
    },
    onOk() {
      this.dialog=false;
      let selectedUser = this.selectedUser;
      this.selectedUser = null;
      this.$refs.autoComplete.remove();
      this.$emit('select-user-dialog-confirmation', selectedUser)
      if (this.resolve) this.resolve(selectedUser);
    }
  }
}
</script>

<style scoped>

</style>
