<template>
  <v-data-table
      :headers="headers"
      :items="students"
      :search="search"
      :items-per-page=-1
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Student Roster</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field class="mx-4"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
        ></v-text-field>
        <!--                    <v-btn color="primary" class="mx-4">Add More Students</v-btn>-->
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <!--                  <v-icon-->
      <!--                      class="mr-2"-->
      <!--                      @click="editStudent(item)"-->
      <!--                  >-->
      <!--                    mdi-pencil-->
      <!--                  </v-icon>-->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="mr-4"
              @click="dropStudentFromRoster(item)"
          >
            mdi-minus-circle
          </v-icon>
        </template>
        <span>Drop from class roster</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <span>No Students in this class yet</span>
    </template>
  </v-data-table>
</template>
<script>
  export default {
    props: {
      students: {
        type: Array,
        default: () => [],

      },
      classId: {
        type: Number,
        default: null
      }
    },
    data: () => ({
        headers: [
          { text: 'First Name', value: 'first_name', align: 'start'},
          { text: 'Last Name', value: 'last_name' },
          { text: 'Username', value: 'username' },
          { text: 'Student Id', value: 'id' },
          { text: 'User Id', value: 'user_id' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        search: ''
    }),
    methods: {
      async dropStudentFromRoster(student) {
        const confirmed = window.confirm(`You are about to remove ${student.username} from this class`)
        if (confirmed) {
          let url = `/class/${this.classId}/students/${student.id}`
          const {status} =  await this.axios.delete(url);
          if (status == 200) {
            this.students = this.students.filter(item => student.id !== item.id)
          }
        }
      },
    }

  }

</script>
