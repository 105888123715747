<template>
  <v-row>
    <confirm-dialog ref="confirm" />
    <v-dialog
        v-model="dialog"
        hide-overlay
        fullscreen
        transition="dialog-bottom-transition"
    >
      <div>
        <v-card elevation="3" height="100vh">
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit class <b>{{ item.name }}</b></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="save"
              >
                Save
              </v-btn>
              <v-menu
                  bottom
                  right
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
<!--                  <v-list-item @click="copy">-->
<!--                    <v-list-item-icon><v-icon color="darken-2">mdi-lock-reset</v-icon></v-list-item-icon>-->
<!--                    <v-list-item-title>Reset Password</v-list-item-title>-->
<!--                  </v-list-item>-->
<!--                  <v-list-item @click="impersonate">-->
<!--                    <v-list-item-icon><v-icon color="darken-2">mdi-login</v-icon></v-list-item-icon>-->
<!--                    <v-list-item-title>Impersonate</v-list-item-title>-->
<!--                  </v-list-item>-->
<!--                  <v-list-item v-if="isTeacher" @click="showConvertToStudentDialog">-->
<!--                    <v-list-item-icon><v-icon color="darken-2">mdi-account-convert</v-icon></v-list-item-icon>-->
<!--                    <v-list-item-title>Convert To Student</v-list-item-title>-->
<!--                  </v-list-item>-->
                  <v-divider></v-divider>
                  <v-list-item @click="reset">
                    <v-list-item-icon><v-icon color="darken-2">mdi-undo</v-icon></v-list-item-icon>
                    <v-list-item-title>Undo Unsaved Changes</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleConnect">
                    <v-list-item-icon><v-icon color="red darken-2">mdi-content-copy</v-icon></v-list-item-icon>
                    <v-list-item-title>Add another teacher to this class</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleCopy">
                    <v-list-item-icon><v-icon color="red darken-2">mdi-content-copy</v-icon></v-list-item-icon>
                    <v-list-item-title>Copy to another teacher</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDelete">
                    <v-list-item-icon><v-icon color="red darken-2">mdi-delete</v-icon></v-list-item-icon>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>
          </v-toolbar>
          <ClassEditor ref="classEditor"></ClassEditor>
        </v-card>

      </div>
    </v-dialog>
  </v-row>
</template>
<script>
import _ from 'lodash';
// import {EventBus, NOTIFY_EVENT} from "@/eventbus";
import ConfirmDialog from "@/components/ConfirmDialog";
import {mapState} from "vuex";
import ClassEditor from "@/components/ClassEditor";
import {EventBus, NOTIFY_EVENT} from "@/eventbus";


export default {
  name: 'ClassEditorDialog',
  props: {
    onDelete: {
      type: Function,
      default: () => {
      },
    },
    onClose: {
      type: Function,
      default: () => {
      },
    }
  },
  watch: {
    dialog: function(newVal) {
      if (!newVal) this.onClose()
    }
  },
  components: {ClassEditor, ConfirmDialog},
  computed: {
    ...mapState(['groups']),
    isCognitoAdmin() {
      return this.groups.indexOf('admin') > -1
    }
  },
  methods: {
    notify(message, type) {
      EventBus.$emit(NOTIFY_EVENT, {message, type})
    },
    async show(item) {
      this.item = null;
      this.dialog = true;
      this.originalItem = item;
      this.item = _.cloneDeep(item);
      this.$nextTick(function () {
        this.$refs.classEditor.load(this.item.id)
      })
    },
    reset() {
      this.item = _.cloneDeep(this.originalItem);
    },
    handleDelete() {
      this.dialog = false;
      this.onDelete(this.item)
    },
    handleCopy() {
      this.$refs.classEditor.copy()
    },
    handleConnect() {
      this.$refs.classEditor.connect();
    },
    async showConvertToStudentDialog(user_id) {
      const confirmed = await this.$refs.confirm.open(
              "Confirm",
              "Are you sure you want to delete this class?"
          )
      if (confirmed) {
        this.loading = true;
        await this.convertToStudent(user_id);
        this.loading = false;
      }
    },
     async save() {
      try {
        const {data, status} = await this.$refs.classEditor.save()
        if (status !== 200) {
          this.notify(`Error: server returned status: ${status}`, 'error')
        }
        if (data.success === true) {
          this.dialog = false;
          this.originalItem = this.item;
          this.$root.$emit('notify', {'message': `Changes saved for class ${this.item.name}.`, type: 'success'})
          this.dialog = false
          this.originalItem = this.item;
        } else {
          this.$root.$emit('notify', {'message': `Error saving class ${this.item.name}.`, type: 'error'})
        }
      } catch (e) {
        this.$root.$emit('notify', {'message': `Error saving class ${this.item.name}.`, type: 'error'})
      }

    }
  },
  // created() {
  //   EventBus.$on(NOTIFY_EVENT, ({message, type}) => {
  //     this.notify(message, type)
  //   })
  // },
  // destroyed() {
  //   EventBus.$off(NOTIFY_EVENT)
  // },
  data() {
    return {
      dialog: false,
      item: {},
    }
  },

}
</script>
