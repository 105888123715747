<template>
  <span>
  <v-autocomplete
      class="mx-4"
      v-model="model"
      :items="users"
      :loading="isLoading"
      :search-input.sync="search"
      color="blue"
      hide-no-data
      hide-selected
      :item-text="getText"
      item-value="id"
      label="Teachers"
      placeholder="Start typing to Search"
      prepend-icon="mdi-account-star"
      return-object
      >
  </v-autocomplete>
    <v-expand-transition>
            <v-list
                v-if="model"
                class="lighten-3"
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="model.username"></v-list-item-title>
                  <v-list-item-subtitle>Username</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="model.email"></v-list-item-title>
                  <v-list-item-subtitle>E-mail</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-text="model.first_name+' '+model.last_name"></v-list-item-title>
                  <v-list-item-subtitle>Name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
    </v-expand-transition>
    </span>
</template>

<script>
import _ from "lodash";
export default {
  name: 'UserAutoComplete',
  props: {
    onSelected: {
      type: Function,
      default: (val) => { console.log(val) }
    }
  },
  data: function() {
    return {
      cancelToken: null,
      users: [],
      lastFetchQuery: null,
      search: '',
      options: {
        sortBy: ['username'],
        sortDesc: [false]
      },
      searchOptions: {
        fields: {
          email: true,
          username: true,
          first_name: false,
          last_name: false
        },
      },
      isLoading: false,
      model: null
    }},
  computed: {
    querySeachParams() {
      let url = ''
      if (!_.isEmpty(this.search) || (this.search && this.search.length > 2)) {
        url = 'query=' + encodeURIComponent(this.search);
        const search_fields = _.keys(_.pickBy(this.searchOptions.fields));
        if (!_.isEmpty(search_fields)) url += '&query_fields=' + _.join(search_fields, ',')
        url+='&role=4'
      }
      return url;
    }
  },
  watch: {
    model() {
      this.onSelected(this.model);
    },
    search() {

      // Items have already been requested
      if (this.isLoading) return

      this.isLoading = true

      // Lazily load input items
      this.debouncedGetDataFromApi()

    },
  },
  methods: {
    remove() {
      this.model = null
    },
    getText(item) {
      return `${item.username} (${item.email})`
    },
    async getDataFromApi() {
      const {sortBy, sortDesc} = this.options
      let url = `/user/list?`
      url += `limit=35&offset=0&sort=${sortBy}&desc=${sortDesc}&${this.querySeachParams}`;
      if (url !== this.lastFetchQuery) {
        this.isLoading = true
        try {
          if (this.cancelToken) {
            this.cancelToken.cancel("Operation canceled due to new request.")
          }
          this.cancelToken = this.axios.CancelToken
          const {data, status} = await this.axios(url, {cancelToken: this.cancelToken});
          if (status !== 200) {
            this.isLoading = false;
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          }
          this.users = data.items.map(item => ({...item, roles: JSON.parse(item.roles), user_settings: JSON.parse(item.user_settings)}));

        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.isLoading = false
        }
      }
    },
  },
  created() {
    this.debouncedGetDataFromApi = _.debounce(function () {
      this.getDataFromApi()
    }, 500, {leading: false});
  }
}
</script>


<style scoped>

</style>
