<template>
  <div>
    <UserSelectDialog name="user_select" ref="userSelectDialog"/>
  <v-subheader>Class Info
  </v-subheader>
  <v-row class="left">
    <v-col cols="12">
<!--      <v-card elevation="1" class="ma-2">-->
<!--      <v-card-title>Student Info</v-card-title>-->
      <v-skeleton-loader v-if="loading"
          class="'mx-auto pa-3 dark" :boilerplate="false" elevation="0"
          type="list-item-two-line, list-item-three-line, actions"
      ></v-skeleton-loader>
      <v-form v-if="!loading" class="pa-4">
        <v-row class="left">
          <v-col cols="12"
                 sm="6"
                 md="6">
            <v-text-field
                v-model="editItem.id"
                label="Class ID"
                disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12"
                 sm="3"
                 md="3">
            <v-text-field
                v-model="editItem.class_code"
                label="Class Code"
                disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="left">
          <v-col cols="12"
                 sm="12"
                 md="2">
            <v-text-field
                v-model="editItem.name"
                label="Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12"
                 sm="12"
                 md="3">

              <v-combobox
                  v-model="owner"
                  label="Owner"
                  :items="teacherComboItems"
                  item-text="name"
                  item-value="value"
                  ref="ownerCombo"
              >
              </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
          <v-list>
          <v-subheader>Internal Class Settings</v-subheader>
<!--          <v-list-item>-->
<!--            <v-list-item-action>-->
<!--              <v-checkbox v-model="class_settings.new_quiz_ui"></v-checkbox>-->
<!--            </v-list-item-action>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>New Quiz UI</v-list-item-title>-->
<!--              <v-list-item-subtitle>Enables new quiz UI for all students in this class</v-list-item-subtitle>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
          <v-list-item>
            <v-list-item-action>
              <v-checkbox v-model="class_settings.ads_disabled"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Disable Ads</v-list-item-title>
              <v-list-item-subtitle>Disables ads to all students in this class
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          </v-list>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
              <co-teachers-table :teachers="editItem.teachers" :owner-id="editItem.teacher_id" :class-id="editItem.id"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
              <students-roster-table :students="editItem.students" :class-id="editItem.id"/>
          </v-col>
        </v-row>
          <v-row>
            <v-col cols="12">
              <UserTree v-if="!loading" :root-object="editItem"></UserTree>
            </v-col>
          </v-row>
      </v-form>
<!--      </v-card>-->
    </v-col>
  </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import UserTree from "@/components/tree/UserTree";
import UserSelectDialog from "@/components/UserSelectDialog";
import {mapActions} from "vuex";
import StudentsRosterTable from "@/components/class/StudentsRosterTable";
import CoTeachersTable from "@/components/class/CoTeachersTable";

const DEFAULT_CLASS_SETTINGS = {
  new_quiz_ui: true,
  ads_disabled: false,
}

export default {

  name: "ClassEditor",
  components: {CoTeachersTable, StudentsRosterTable, UserSelectDialog, UserTree},
  data() {
    return {
      originalItem: null,
      editItem: null,
      owner: null,
      loading: true,
      class_id: null,
      class_settings: _.cloneDeep(DEFAULT_CLASS_SETTINGS),
    }
  },
  created() {
    // this.loadStudentData()
  },
  computed: {
    teacherComboItems() {
      return _.map(this.editItem.teachers, t => ({name: t.first_name + ' ' + t.last_name, value: t.id }))
    }
  },
  methods: {
    ...mapActions(['setGlobalLoading']),
    edit() {

    },

    fetchTeachers() {

    },

    fetchClasses() {

    },
    copy() {
      this.copyToTeacher(this.editItem)
    },
    connect() {
      this.connectToTeacher(this.editItem)
    },



    // async stopTrackingStudent(student) {
    //   const confirmed = window.confirm(`You are about stop tracking ${student.username} by this teacher, this will remove the student completely from this teacher and all that teacher's classes`)
    //   if (confirmed) {
    //     let url = `/teacher/${this.editItem.teacher_id}/students/${student.id}`
    //     const {status} =  await this.axios.delete(url);
    //     if (status == 200) {
    //       this.editItem.students = this.editItem.students.filter(item => student.id !== item.id)
    //     }
    //
    //   }
    //
    // },

    async save() {
      let url = '/class/' + this.editItem.id
      const savedFields = ['name']
      const payload = _.pick(this.editItem, savedFields)
      payload.class_setting = JSON.stringify(this.class_settings);
      if (this.owner?.value) payload.teacher_id = this.owner.value;
      return await this.axios.post(url, payload);
    },

    async load(class_id) {
      this.class_id = class_id
      this.originalItem = null
      this.editItem = null
      this.owner = null;
      return this.loadClassData();
    },

    async copyToTeacher(clazz) {
      const target_teacher_user = await this.$refs.userSelectDialog.show();
      this.setGlobalLoading(true);
      const {data} = await this.axios.get(`/user/${target_teacher_user.id}/teacher`)
      if (data.items.length > 0) {
        const target_teacher = data.items[0];
        target_teacher.type = 'teacher';
        target_teacher.user_id = target_teacher_user.id
        if (target_teacher && target_teacher.id) {
          let url = `/class/${clazz.id}/copy`
          const payload = {target_teacher_id: target_teacher.id}
          const {data, status} = await this.axios.post(url, payload);
          if (status !== 200) {
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          } else {
            this.$root.$emit('notify', {message: `Class copied successfully to teacher ${target_teacher_user.username}`, type: 'success'})
          }
        }
      }
      this.setGlobalLoading(false);
    },

    async connectToTeacher(clazz) {
      const target_teacher_user = await this.$refs.userSelectDialog.show();
      this.setGlobalLoading(true);
      const {data} = await this.axios.get(`/user/${target_teacher_user.id}/teacher`)
      if (data.items.length > 0) {
        const target_teacher = data.items[0];
        target_teacher.type = 'teacher';
        target_teacher.user_id = target_teacher_user.id
        if (target_teacher && target_teacher.id) {
          let url = `/class/${clazz.id}/teacher/${target_teacher.id}`
          const {data, status} = await this.axios.put(url);
          if (status !== 200) {
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          } else {
            this.$root.$emit('notify', {message: `Class copied successfully to teacher ${target_teacher_user.username}`, type: 'success'})
          }
        }
      }
      this.setGlobalLoading(false);
    },

    async loadClassData() {
        this.loading = true;
        const {data} = await this.axios.get(`/class/${this.class_id}`)
        if (data) {
          this.originalItem = data;
          this.originalItem.type = 'class';
          this.originalItem.class_id = this.class_id
          this.editItem = _.cloneDeep(this.originalItem);
          this.editItem.type = 'class';
          this.class_settings = _.assign(this.class_settings, JSON.parse(this.originalItem.class_setting))
          this.owner = _.find(this.teacherComboItems, {value: this.editItem.teacher_id})
        }
        this.loading = false;
        return this.editItem
    }
  }
}
</script>

<style scoped>

</style>
